<template>
  <div class="field-item">
    <div class="field-item__value">
      {{ itemVal.label }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'BreakField',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    itemVal: {
      get() {
        return this.item;
      },
      set(data) {
        this.$emit('update:item', data);
      },
    },
  },
};
</script>

<style lang="scss">
@import "../../sass/_variables.scss";
@import "../../sass/fields";
</style>
